<template>
  <div id="tutoringWrap">
    <section id="tutoringContent">
      <div class="class-plan">
        <div class="sub-visual sub-visual--allpass">
          <div class="sub-visual__wrapper">
            <h2 class="sub-visual__title">
              모든 영어를
              <br />
              한번에
            </h2>
            <p class="sub-visual__desc">
              튜터링으로
              <br />
              말하고 듣고 쓰세요
            </p>
          </div>
        </div>
        <div class="item-center">
          <CouponBox />
        </div>

        <!-- <div class="coupon-download-contaienr">
          <button class="coupon-download-box" @click="showCouponModal">
            <div class="box">
              <div class="left-img" />
              <div class="text">
                <p class="desc">튜터링 할인 페스타</p>
                <p class="title">할인쿠폰 4종</p>
              </div>
            </div>
            <div class="download-img" />
            <div class="download-done">
              다운
              <br />
              완료
            </div>
          </button>
          <p class="desc">자세한 쿠폰 사용 안내는 수강권 유의사항을 참고해 주세요.</p>
        </div> -->

        <div class="plan__section">
          <h2 class="section__heading">
            언택트 시대
            <br style="display: block" />
            더 자유로운 튜터링
          </h2>
          <img
            src="//res.tutoring.co.kr/res/images/img/plans/img_section3_text_circle.png"
            alt="SPEAKING, WRITING, LISTENING"
            class="image-circle"
          />
          <p class="section__desc">
            일상 속 완벽한 어학 환경
            <br />
            원어민 회화 + AI 학습 + 첨삭까지!!
          </p>
        </div>

        <div class="plan__section plan__point">
          <h2 class="section__heading">이 모든 것을 담아도</h2>
          <h2 class="section__heading">합리적인 국민 가격</h2>
          <div class="point__wrapper">
            <div class="point__price">
              <div style="position: relative; display: inline-block">
                <div style="line-height: 47px; font-size: 30px">
                  월
                  <div class="number" style="vertical-align: baseline">
                    {{ priceComma(planData?.groupPlans?.purchase?.minMonthly?.price) }}
                  </div>
                  원
                </div>
              </div>
            </div>
            <img
              src="//res.tutoring.co.kr/res/images/img/plans/img_section4_begin_1600w_3.png"
              alt=""
              class="image-graph"
            />
            <p class="section__desc">{{ planData?.groupPlans?.purchase?.minMonthly?.title_plan }}</p>
          </div>
        </div>

        <div class="plan__section plan__benefit">
          <div class="benefit__wrapper">
            <div class="benefit__inner">
              <h2 class="section__heading">
                올-
                <br style="display: block" />
                {{ priceComma(planData?.groupPlans?.purchase?.minMonthly?.price) }}원에
                <br style="display: block" />
                이런 것도 준다구요?
              </h2>
              <p class="section__desc">
                튜터와의 1:1 수업 말고도
                <br />
                드리고 싶은게 너무 많아요.
                <br />
                수업 다시 듣기, 수업 피드백은 기본이죠!
              </p>
            </div>

            <div class="benefit__inner">
              <p class="section__desc">여기에,</p>
              <ul class="benefit__list">
                <li class="list__item">
                  <span class="text">
                    700개 이상의
                    <b>토픽 카드 무제한</b>
                    무료
                  </span>
                  <span class="tag">매주 업데이트</span>
                </li>
                <li class="list__item">
                  <span class="text">
                    40개 이상의
                    <b>인기코스 무제한</b>
                    무료
                  </span>
                  <span class="tag">매달 업데이트</span>
                </li>
                <li class="list__item">
                  <span class="text">
                    <b>AI 튜터 무제한</b>
                    스피킹 수업
                  </span>
                </li>
                <li class="list__item">
                  <span class="text">
                    월 1회
                    <b>이메일 학습 컨텐츠</b>
                    제공
                  </span>
                </li>
                <li class="list__item">
                  <span class="text">
                    전문 학습 코치의
                    <b>1:1 학습 컨설팅</b>
                  </span>
                </li>
                <li class="list__item">
                  <span class="text">
                    모든 토픽 카드
                    <b>무제한 듣기</b>
                    콘텐츠 제공
                  </span>
                </li>
                <li class="list__item">
                  <span class="text">
                    원어민 튜터의
                    <b>1:1</b>
                    첨삭까지
                  </span>
                </li>
              </ul>
              <p class="section__desc">
                이 모든 것을 모두 담아도
                <br />
                <b class="point-text">
                  #더없이 합리적인 가격 월 {{ priceComma(planData?.groupPlans?.purchase?.minMonthly?.price) }}원부터
                </b>
              </p>
              <p class="section__desc">
                더이상 비용 걱정하지 말고
                <br />
                영어 공부에만 집중하세요.
              </p>
            </div>
          </div>
        </div>

        <div class="plan__section">
          <h2 class="section__heading">
            1,000여 명의 전문 튜터가
            <br style="display: block" />
            24시간 대기 중
          </h2>
          <div class="plan__tutor">
            <div class="circle-effect">
              <div class="circle circle--1">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile12_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--2">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile11_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--3">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile13_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--4">
                <span class="live">LIVE</span>
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile14_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--5">
                <span class="live">LIVE</span>
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile1_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--6">
                <span class="live">LIVE</span>
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile4_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--7">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile2_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--8">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile3_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--9">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile5_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--10">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile10_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--11">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile9_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--12">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile8_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--13">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile7_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--14">
                <img src="//res.tutoring.co.kr/res/images/img/plans/img_profile6_circle_3x.png" alt="" />
              </div>
              <div class="circle circle--purple"></div>
              <div class="circle circle--yellow"></div>
              <div class="circle circle--orange"></div>
              <div class="circle circle--blue"></div>
            </div>
          </div>
          <ul class="plan__summary">
            <li class="list__item">
              <span class="summary__text">30초</span>
              튜터와 만나는 시간
            </li>
            <li class="list__item">
              <span class="summary__text">11:1</span>
              경쟁률로 검증된 튜터
            </li>
            <li class="list__item">
              <span class="summary__text">750</span>
              업계 최다 커리큘럼수
            </li>
          </ul>
        </div>

        <div class="plan__section plan__review">
          <h2 class="section__heading">
            Let's start
            <br style="display: block" />
            You can do it!
          </h2>
          <div class="review__count">
            <div class="count__box">
              <h3 class="count__title">누적 가입자</h3>
              <span>250만명</span>
            </div>
            <div class="count__box">
              <h3 class="count__title">수업 만족도</h3>
              <div class="review__score">
                <div class="score__bar"><span class="status" style="width: 50%"></span></div>
                5.0
              </div>
            </div>
          </div>
        </div>

        <div class="plan__section plan__partner">
          <h2 class="section__heading">
            기업들도
            <br style="display: block" />
            선호하는
            <br style="display: block" />
            요즘영어
          </h2>
          <p class="section__desc">대한민국 대표 기업들이 튜터링과 함께합니다.</p>

          <ul class="partner__list">
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_samsung_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_hyundaicard_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_hyundaicapital_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_skhynix_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_gsshop_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_yuhan_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_dongwha_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_telechips_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_jobkorea_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_spc_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_brkorea_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_fedex_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_lotte_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_autonics_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_pubg_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_bluebird_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_skens_logo_2x.jpg" alt="" />
            </li>
            <li class="list__item">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_skbroadband_logo_2x.jpg" alt="" />
            </li>
          </ul>
        </div>

        <!-- 쿠폰 다운로드 영역 -->
        <!-- <div class="coupon-download-contaienr">
          <button class="coupon-download-box button" @click="showCouponModal" type="button">
            <div class="box">
              <div class="left-img" />
              <div class="text">
                <p class="desc">튜터링 할인 페스타</p>
                <p class="title">할인쿠폰 4종</p>
              </div>
            </div>
            <div class="download-img" />
            <div class="download-done">
              다운
              <br />
              완료
            </div>
          </button>
          <p class="desc">자세한 쿠폰 사용 안내는 수강권 유의사항을 참고해 주세요.</p>
        </div> -->

        <div v-if="isCouponClicked">
          <div class="layer_comm">
            <PopupToast :isPopup="isPopup"><p v-html="cuponPopupMessage"></p></PopupToast>
            <div class="dimmed"></div>
            <div class="inner_wrapper">
              <div class="inner_layer">
                <div class="coupon_title">
                  즉시 사용 가능한
                  <br />
                  추가 할인 쿠폰
                </div>
                <div class="coupon__container" id="togo1">
                  <div
                    v-for="(coupon, index) in couponData?.coupon?.data?.coupons"
                    :key="index"
                    @click="
                      !hasCouponIdxArray(coupon.idx) && !coupon.hasCoupon && postCouponDownload(coupon.idx, index)
                    "
                    :class="[
                      { 'coupon coupon100000': coupon.dc_money === 100000 },
                      { 'coupon coupon50000': coupon.dc_money === 50000 },
                      { 'coupon coupon30000': coupon.dc_money === 30000 },
                      { 'coupon coupon10000': coupon.dc_money === 10000 },
                      { 'is-download': coupon.hasCoupon || hasCouponIdxArray(coupon.idx) },
                    ]"
                  ></div>
                  <button v-if="hasCouponIdx.length < 4" class="coupon_button" @click="postCouponDownloadAll()">
                    쿠폰 한 번에 받기
                  </button>
                  <button
                    v-if="hasCouponIdx.length >= 4"
                    class="coupon_button_enabled"
                    @click="postCouponDownloadAll()"
                    disabled
                  >
                    다운로드 완료
                  </button>
                </div>
              </div>
              <div class="coupon_notice">
                <div class="notice_title">
                  <p>쿠폰 유의 사항</p>
                </div>
                <div class="notice_desc">
                  <p>· 쿠폰 다운로드 후 사용이 가능합니다.</p>
                  <p>· 일부 수강권에서는 쿠폰 사용이 불가합니다.</p>
                </div>
              </div>
              <div class="extra_wrapper">
                <button class="extra_button" @click="showCouponModal">
                  <img
                    class="close_icon"
                    src="//res.tutoring.co.kr/res/images/img/plans/ic_cp_modal_close.png"
                    alt="close"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="plan__section2">
          <div class="section2__container section2__container--1st">
            <div class="section2__content">
              <span class="section2__text">1:1 회화 수업</span>
              <h2 class="section2__title">
                10분, 20분
                <br />
                바쁜 일상 속에서
                <br />
                자유롭게 선택.
              </h2>
              <p class="section2__desc">
                때론 가벼운 10분으로도 충분해요. 여유있을 때는 20분 수업으로 집중해볼까요? 내 상황에 맞게 이제 선택할 수
                있어요. 10분부터 최대 60분까지 자유롭게 수업해보세요.
              </p>
            </div>
            <div class="section2__image">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_video1.gif" alt="" />
            </div>
          </div>
          <div class="section2__container section2__container--2nd section2__container--even">
            <div class="section2__content">
              <span class="section2__text">AI 튜터 무제한 수업</span>
              <h2 class="section2__title">
                예습, 실전, 복습
                <br />
                예습, 실전, 복습
                <br />
                연습만이 살길.
              </h2>
              <p class="section2__desc">
                원어민 튜터와의 수업이 긴장되시나요? 그럴 땐 AI튜터와 예습하며 긴장을 풀어보세요. 실수해도 괜찮아요.
                부담없이 말하다보면, 두려움은 줄고 실력은 늘게 될 거예요.
              </p>
            </div>
            <div class="section2__image">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_video2.gif" alt="" />
            </div>
          </div>
          <div class="section2__container section2__container--3rd">
            <div class="section2__content">
              <span class="section2__text">1:1 첨삭 서비스</span>
              <h2 class="section2__title">
                나만의 첨삭
                <br />
                선생님으로
                <br />
                쓰기까지 완성.
              </h2>
              <p class="section2__desc">
                영어의 완성은 말하기, 쓰기라고 했던가요? 영작 실력까지 늘리고 싶다면 이제 나만의 첨삭 선생님에게
                물어보세요. 빠르고 정확한 교정으로 완벽한 영어 실력을 만들어 줄 거예요.
              </p>
            </div>
            <div class="section2__image">
              <img src="http://res.tutoring.co.kr/res/images/img/plans/img_video3.gif" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="sub-fixed">
        <div class="fixed__wrapper">
          <!-- <a href="#" class="fixed__link">문의하기</a> -->
          <div class="fixed__box">
            <button @click="goToApply" type="button" class="fixed__button">수강권 둘러보기</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import promotionMixins from '../../../common/mixins/promotion';
import PopupToast from '../../../components/PopupToast.vue';
import CouponBox from '../../promotion/component/CouponBox.vue';

export default {
  name: 'PlanAllPass',
  components: {
    PopupToast,
    CouponBox,
  },
  mixins: [promotionMixins],
  data() {
    return {
      isCouponClicked: false,
      planData: {},
      couponData: { plans: [{ cp_idx: 0 }] },
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',
      hasCouponIdx: [],
      isDownload: true,
    };
  },
  created() {
    this.getCouponData();
    this.getPlanData();
    this.goHome();
  },
  mounted() {
    if (localStorage.getItem('key')) {
      this.setTrackingUserAction();
    }
  },
  methods: {
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2/classApply`;
      }
    },
    setTrackingUserAction(): void {
      const device = inappTools.isInApp() ? 'APP' : 'WEB';
      api.post('/app/users/setTracking', { event_name: 'detail', page_name: 'allpass', device_type: `${device}` });
    },
    goToApply(): void {
      this.$router.push({ name: 'planAllPass', params: { prevPage: 'allPass' } });
    },
    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    showCouponModal(): void {
      this.isCouponClicked = !this.isCouponClicked;
      if (this.isCouponClicked) {
        this.trackingUserAction(`365_readytogo_coupon_down`);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.pageYOffset}`;
        document.body.style.width = '100%';
      } else {
        this.trackingUserAction(`365_readytogo_Coupon_close`);
        document.body.style.overflow = 'unset';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    },
    async getPlanData(): Promise<any> {
      this.planData = await api.get('/app/chargePlan/v2/allPass/home').then((response: AxiosResponse) => response.data);
    },
    async getCouponData(): Promise<any> {
      this.couponData = await api.get('/app/etc/promotion/365low').then((response: AxiosResponse) => response.data);

      this.couponData.coupon.data.coupons.forEach((coupon) => {
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      if (this.hasCouponIdx.length >= 4) {
        this.isDownload = !this.isDownload;
      }
      this.choicedOptionIdx = this.couponData.plan[1].cp_idx;
      this.choicedOptionData = { ...this.couponData.plan[1] };
    },
    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      this.trackingUserAction(`365_readytogo_Coupon_${index + 1}`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      this.trackingUserAction(`365_readytogo_Coupon_all`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        if (this.hasCouponIdx.length >= 4) {
          this.isDownload = !this.isDownload;
          return;
        }
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: '1053,1054,1055,1056' })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(1053, 1054, 1055, 1056);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
  },
  computed: {
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
  },
};
</script>

<style>
.partner__list {
  animation: where 30s linear infinite;
}

@keyframes where {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.coupon-download-contaienr {
  background-color: #fff;
  padding: 20px 20px 50px 20px;
}
.coupon-download-contaienr .coupon-download-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #5d04d9;
  border-radius: 20px;
  height: 96px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
@media (max-width: 767px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .coupon-download-contaienr .coupon-download-box {
    width: 530px;
  }
}
.coupon-download-contaienr .coupon-download-box .box {
  display: flex;
  align-items: center;
}
.coupon-download-contaienr .coupon-download-box .box .left-img {
  width: 50px;
  height: 41px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/ic_plan_coupon.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50px 41px;
  margin-right: 20px;
  margin-left: 25px;
}
.coupon-download-contaienr .coupon-download-box .text .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.5px;
  color: #afabb7;
  margin-bottom: 5px;
}
.coupon-download-contaienr .coupon-download-box .text .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -1px;
  color: #121214;
}
.coupon-download-contaienr .coupon-download-box .download-img {
  width: 46px;
  height: 46px;
  background-image: url('//res.tutoring.co.kr/res/images/img/plans/btn_down.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 46px 46px;
  margin-right: 25px;
}
.coupon-download-contaienr .coupon-download-box .download-done {
  width: 96px;
  height: 100%;
  background: #efe6fb;
  border-radius: 0 20px 20px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(93, 4, 217, 0.5);
  padding: 32px 35px;
}
.coupon-download-contaienr .desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #afabb7;
}
.layer_comm {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layer_comm .dimmed {
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.3;
}

.layer_comm .inner_wrapper {
  position: absolute;
  top: 54%;
  right: 0;
  left: 0;
  width: 348px;
  /* height: 100%; */
  margin: 0 auto;
  transform: translateY(-50%);
}

.layer_comm .inner_layer {
  display: flex;
  top: inherit;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 54vh;
  /* height: 478px; */
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 30px 0;
  margin: 0 auto;
  position: initial;
}
.layer_comm .coupon_title {
  width: 42%;
  margin: 0 auto 10px auto;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 25px;
  font-size: 20px;
}
.coupon__container {
  overflow: auto;
  width: 100%;
}

.coupon__container .coupon {
  width: 258px;
  height: 102px;
  margin: 0 auto 8px auto;
  cursor: pointer;
}
.coupon.coupon100000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_10_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon100000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_10_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_5_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon50000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_5_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_3_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon30000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_3_off.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon_1_on.png) no-repeat 50%;
  background-size: contain;
}
.coupon.coupon10000.is-download {
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_coupon_1_off.png) no-repeat 50%;
  background-size: contain;
}

.coupon_button {
  width: 258px;
  background: #c021c3;
  border-radius: 10px;
  padding: 12px 16px;
  color: #ffff;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_button_enabled {
  width: 258px;
  background: #f1f0f5;
  border-radius: 10px;
  padding: 12px 16px;
  color: #d6d3dd;
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 25px;
}
.coupon_notice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  /* position: absolute; */
  background-color: #f1f1f1;
  border-radius: 0 0 20px 20px;
  padding: 20px 24px;
}
.coupon_notice .notice_title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}
.coupon_notice .notice_desc {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #666666;
}
.item-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.extra_wrapper {
  padding: 10px 0;
  text-align: center;
  /* position: relative;
	top: 740px; */
}
.extra_wrapper .extra_button {
  color: #fff;
  background-color: #121214;
  border-radius: 25px;
  font-size: 25px;
  padding: 2px 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.extra_wrapper .extra_button .close_icon {
  width: 16px;
  height: 16px;
}
.coupon-wrapper {
  margin-bottom: -48px;
  padding: 20px;
  background-color: #f5f5f5;
}
</style>
