
import { AxiosResponse } from 'axios';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import promotionMixins from '../../../common/mixins/promotion';
import PopupToast from '../../../components/PopupToast.vue';
import CouponBox from '../../promotion/component/CouponBox.vue';

export default {
  name: 'PlanAllPass',
  components: {
    PopupToast,
    CouponBox,
  },
  mixins: [promotionMixins],
  data() {
    return {
      isCouponClicked: false,
      planData: {},
      couponData: { plans: [{ cp_idx: 0 }] },
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',
      hasCouponIdx: [],
      isDownload: true,
    };
  },
  created() {
    this.getCouponData();
    this.getPlanData();
    this.goHome();
  },
  mounted() {
    if (localStorage.getItem('key')) {
      this.setTrackingUserAction();
    }
  },
  methods: {
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2/classApply`;
      }
    },
    setTrackingUserAction(): void {
      const device = inappTools.isInApp() ? 'APP' : 'WEB';
      api.post('/app/users/setTracking', { event_name: 'detail', page_name: 'allpass', device_type: `${device}` });
    },
    goToApply(): void {
      this.$router.push({ name: 'planAllPass', params: { prevPage: 'allPass' } });
    },
    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    showCouponModal(): void {
      this.isCouponClicked = !this.isCouponClicked;
      if (this.isCouponClicked) {
        this.trackingUserAction(`365_readytogo_coupon_down`);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.pageYOffset}`;
        document.body.style.width = '100%';
      } else {
        this.trackingUserAction(`365_readytogo_Coupon_close`);
        document.body.style.overflow = 'unset';
        document.body.style.position = '';
        document.body.style.width = '';
      }
    },
    async getPlanData(): Promise<any> {
      this.planData = await api.get('/app/chargePlan/v2/allPass/home').then((response: AxiosResponse) => response.data);
    },
    async getCouponData(): Promise<any> {
      this.couponData = await api.get('/app/etc/promotion/365low').then((response: AxiosResponse) => response.data);

      this.couponData.coupon.data.coupons.forEach((coupon) => {
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      if (this.hasCouponIdx.length >= 4) {
        this.isDownload = !this.isDownload;
      }
      this.choicedOptionIdx = this.couponData.plan[1].cp_idx;
      this.choicedOptionData = { ...this.couponData.plan[1] };
    },
    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      this.trackingUserAction(`365_readytogo_Coupon_${index + 1}`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      this.trackingUserAction(`365_readytogo_Coupon_all`);

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        if (this.hasCouponIdx.length >= 4) {
          this.isDownload = !this.isDownload;
          return;
        }
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: '1053,1054,1055,1056' })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(1053, 1054, 1055, 1056);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
  },
  computed: {
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
  },
};
